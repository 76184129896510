import React from 'react'
import { motion } from 'framer-motion'
import toplogo from 'assets/img/logos/matador-labs-text-logo.svg'
import spinninghead from 'assets/img/rotating-bull-head.gif'

export const TopBanner = () => {

    return (
        <div className='landing-container top-banner'>
            <div className="padded-container">
                <div className="top-logo">
                    <img src={toplogo} alt="toplogo" className='toplogo' />
                </div>
                <motion.div initial={{ scale: 1 }} whileHover={{ scale: 0.95 }} whileTap={{ scale: 0.85 }} className="spinninghead-cnt">
                    <img src={spinninghead} alt="spinninghead" className='spinninghead' />
                </motion.div>
            </div>
        </div>
    )
}
