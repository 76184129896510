import React, { useRef } from 'react';
import { TopBanner } from "./TopBanner";
import { MidAreaA, MidAreaB } from "./MidAreas";
import { ContactArea } from './ContactArea';

import './index.scss';

export const LandingPage = () => {
    const connectScrollRef = useRef(null);

    return (
        <div className='landing-page'>
            <TopBanner />
            <MidAreaA />
            <MidAreaB connectScrollRef={connectScrollRef} />
            <ContactArea connectScrollRef={connectScrollRef} />
        </div>
    )
}
