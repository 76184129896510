import React from 'react'
import './index.scss'

export const Button = ({ text, size, theme }) => {
    return (
        // default styles are button and regular-size-button

        <div className={`button-container`}>
            <div className={`button ${size === "big" ? "big-size-button" : "regular-size-button"} ${theme === "white" ? "white-theme" : "default-theme"}`}>
                {text}
            </div>        </div>

    )
}
