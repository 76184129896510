import { useState, useEffect } from 'react'
// import babybulllogobox from 'assets/img/logos/BabyBulls-Logo-Box.png'
import firebirdslogobox from 'assets/img/logos/Firebirds-Logo-Box.png'
import thebullclublogobox from 'assets/img/logos/The-Bull-Club-Logo-Box.png'

import babybulllogo from 'assets/img/logos/BBs-Logo.svg'
import firebirdslogo from 'assets/img/logos/Firebirds-Logo.svg'
import thebullclublogo from 'assets/img/logos/Bull-Club-Logo.svg'

import thebullclubslide from 'assets/img/landingpage/bull-club-slide.png'
import firebirdslide from 'assets/img/landingpage/firebirds-slide.png'
import babybullslide from 'assets/img/landingpage/baby-bull-slide.png'

import matadorbullheadlogo from 'assets/img/logos/matador-labs-bull-head-logo.svg'
import matadorbullheadlogowhite from 'assets/img/logos/matador-labs-bull-head-logo-white.svg'
import globelogo from 'assets/img/icons/globe.svg'
import xtwitterlogo from 'assets/img/icons/xtwitter.svg'
import { motion, AnimatePresence, useAnimation } from 'framer-motion'
import { useInView } from "react-intersection-observer";
import bullblack from 'assets/img/landingpage/Bull-black-side.png'
import { Button } from 'components/Button'

export const MidAreaA = () => {



    const leftTween = {
        hidden: { x: -40 },
        visible: {
            x: 0, transition: { duration: 0.7, delay: 0.15, type: 'tween', stiffness: 100 }
        }
    }

    const threeboxpop = {
        hidden: { scale: 0.95 },
        visible: {
            scale: 1, transition: { duration: 0.5, type: 'tween', stiffness: 100 }
        }
    }

    const controls = useAnimation();
    const [ref, inView] = useInView();
    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);



    // to help with bug fixing on different screen sizes
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const updateIsMobile = () => {
            setIsMobile(window.innerWidth <= 1200);
        };

        updateIsMobile();

        window.addEventListener('resize', updateIsMobile);

        return () => {
            window.removeEventListener('resize', updateIsMobile);
        };
    }, []);

    const [currentSection, setCurrentSection] = useState("bullclub")

    return (
        <div className='landing-container mid-area mid-area-a'>
            <div className="padded-container">
                <AnimatePresence>

                    <div className="tint-cnt">
                        <motion.p ref={ref}
                            animate={controls}
                            initial="hidden"
                            variants={leftTween}
                            className="our-title">
                            Our Projects
                        </motion.p>
                        {
                            isMobile ? (<p className="desc">
                                Matador Labs began with The Bull Club and continues to<br></br> innovate and try new ideas in the blockchain industry.<br></br>  Check out what we are working on.
                            </p>) : (
                                <p className="desc">
                                    Matador Labs began with The Bull Club and continues to innovate and try
                                    <br></br> new ideas in the blockchain industry. Check out what we are working on.
                                </p>
                            )
                        }

                        <motion.div className="three-img-cnt" ref={ref}
                            animate={controls}
                            initial="hidden"
                            variants={threeboxpop}>
                            <div className="twopics"
                            >

                                <motion.img
                                    initial={{ scale: 1 }}
                                    whileHover={{ scale: 0.95 }}
                                    transition={{ duration: 0.2, type: 'tween' }}
                                    onHoverStart={() => setCurrentSection("bullclub")}
                                    src={thebullclublogobox}
                                    alt="thebullclublogo"
                                />
                                <motion.img initial={{ scale: 1 }} whileHover={{ scale: 0.95 }} transition={{ duration: 0.2, type: "tween" }}
                                    onHoverStart={() => setCurrentSection("firebirds")}
                                    src={firebirdslogobox} alt="firebirdslogo" />

                            </div>
                            {/* <div className="onepic">

                                <motion.img initial={{ scale: 1 }} whileHover={{ scale: 0.95 }} transition={{ duration: 0.2, type: "tween" }}
                                    onHoverStart={() => setCurrentSection("babybull")}
                                    src={babybulllogobox} alt="babybulllogo" />

                            </div> */}
                        </motion.div>
                        <div className="bottomrightlogo">
                            <img src={matadorbullheadlogo} alt="matadorbullheadlogo" />
                        </div>
                    </div>
                </AnimatePresence>

                <AnimatePresence>

                </AnimatePresence>
                {
                    currentSection === "bullclub" ? (
                        <motion.div className="slide-cnt bullclubslide-cnt" initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            src="image1.jpg"
                            alt="Image 1">
                            <div className="project-logo">
                                <img src={thebullclublogo} alt="thebullclublogo" />
                            </div>
                            <div className="slide-img">
                                <img src={thebullclubslide} alt="thebullclubslide" />
                            </div>
                            <div className="icon-cnt">
                                <a href='https://bull.club/' className="icon-box globe">
                                    <img src={globelogo} alt="globelogo" />
                                </a>
                                <a href='https://twitter.com/TheBullClubNFT' className="icon-box twitter">
                                    <img src={xtwitterlogo} alt="xtwitterlogo" />
                                </a>
                            </div>
                        </motion.div>
                    ) : (
                        currentSection === "firebirds" ? (
                            <motion.div key="image2"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                src="image1.jpg"
                                alt="Image 1"
                                className="slide-cnt firebirdslide-cnt">
                                <div className="project-logo">
                                    <img src={firebirdslogo} alt="firebirdslogo" />
                                </div>
                                <div className="slide-img">
                                    <img src={firebirdslide} alt="firebirdslide" />
                                </div>
                                <div className="icon-cnt">
                                    <a href='https://firebirds.io/' className="icon-box globe">
                                        <img src={globelogo} alt="globelogo" />
                                    </a>
                                    <a href='https://twitter.com/TheFireBirds' className="icon-box twitter">
                                        <img src={xtwitterlogo} alt="xtwitterlogo" />
                                    </a>
                                </div>
                            </motion.div>
                        ) : (
                            <motion.div key="image3"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                src="image2.jpg"
                                alt="Image 2"
                                className="slide-cnt babybullslide-cnt">
                                <div className="project-logo">
                                    <img src={babybulllogo} alt="babybulllogo" />
                                </div>
                                <div className="slide-img">
                                    <img src={babybullslide} alt="babybullslide" />
                                </div>
                                <div className="icon-cnt">
                                    <a href='https://babybulls.art/' className="icon-box globe">
                                        <img src={globelogo} alt="globelogo" />
                                    </a>
                                    <a href='https://twitter.com/BabyBulls_' className="icon-box twitter">
                                        <img src={xtwitterlogo} alt="xtwitterlogo" />
                                    </a>
                                </div>
                            </motion.div>
                        )
                    )
                }

            </div>
        </div >
    )
}

export const MidAreaB = ({ connectScrollRef }) => {


    const scrollFunc = () => {
        if (connectScrollRef.current) {
            connectScrollRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const rightTween = {
        hidden: { x: 40 },
        visible: {
            x: 0, transition: { duration: 0.7, delay: 0.15, type: 'tween', stiffness: 100 }
        }
    }


    const btnPop = {
        hidden: { scale: 0.85 },
        visible: {
            scale: 1, transition: { duration: 0.5, type: 'tween', stiffness: 400, damping: 40, delay: 0.1 }
        }
    }








    const controls = useAnimation();
    const [ref, inView] = useInView();
    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);






    // ui helpers below


    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const updateIsMobile = () => {
            setIsMobile(window.innerWidth <= 1200);
        };

        updateIsMobile();

        window.addEventListener('resize', updateIsMobile);

        return () => {
            window.removeEventListener('resize', updateIsMobile);
        };
    }, []);



    return (
        <div className='landing-container mid-area mid-area-b'>
            <div className="padded-container">
                <div className="slide-cnt blackarea">
                    <img src={bullblack} alt="bullblack" />
                </div>

                <div className="tint-cnt">
                    <AnimatePresence>
                        <motion.p className="our-title tools-title" ref={ref}
                            animate={controls}
                            initial="hidden"
                            variants={rightTween}>
                            Our Tools
                        </motion.p>
                    </AnimatePresence>

                    <p className="desc">
                        After developing tools for our own projects Matador labs <br></br> is developing them out so that any project can integrate.
                    </p>

                    <AnimatePresence>

                        <div className="tools-list">
                            <div className="boxarea">
                                <motion.div ref={ref}
                                    animate={controls}
                                    initial="hidden"
                                    variants={btnPop} className="grey-box-btn-cnt">
                                    <div className="grey-box-cnt">
                                        Social list
                                    </div>
                                    <div className="coming-soon">
                                        coming soon
                                    </div>
                                </motion.div>

                                <motion.div ref={ref}
                                    animate={controls}
                                    initial="hidden"
                                    variants={btnPop} className="grey-box-btn-cnt">
                                    <div className="grey-box-cnt">
                                        ENGAGE TO EARN
                                    </div>
                                    <div className="coming-soon">
                                        coming soon
                                    </div>
                                </motion.div>
                            </div>


                            <div className="interested-connect-with-us">
                                <div className="ptext">
                                    Interested in integrating one of our tools or be a beta tester?
                                </div>


                                {
                                    isMobile ? (
                                        <div className="btn" onClick={scrollFunc} role='presentation'>
                                            <Button text={'connect with us'} size={"default"} theme={"white"} className="conn-w-us-mob" onClick={scrollFunc} />
                                            <div className="bottommidlogo">
                                                <img src={matadorbullheadlogowhite} alt="bottommidlogo" />
                                            </div>

                                        </div>
                                    ) : (
                                        <div className="btn" onClick={scrollFunc} role='presentation'>
                                            <Button text={'connect with us'} size={"default"} className="conn-w-us" />
                                        </div>

                                    )
                                }
                            </div>

                            <div className="bottomleftlogo">
                                <img src={matadorbullheadlogo} alt="matadorbullheadlogo" />
                            </div>

                        </div>
                    </AnimatePresence>

                </div>
            </div >
        </div >

    )
}
