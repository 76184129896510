import React, { useState, useEffect, useRef } from 'react'
import { motion, AnimatePresence, useAnimation } from 'framer-motion'
import { useInView } from "react-intersection-observer";
import matadorbullheadlogo from 'assets/img/logos/matador-labs-bull-head-logo.svg'
import xtwitterlogo from 'assets/img/icons/xtwitter-btint.svg'
import globelogo from 'assets/img/icons/globe-btint.svg'
import planelogo from 'assets/img/icons/Planesvg.svg'
import emailjs from '@emailjs/browser';

export const ContactArea = ({ connectScrollRef }) => {

    const form = useRef();

    // EMAILJS DETAILS HERE, SERVICE ID, TEMPLATE ID, PUBLIC KEY
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm(
            process.env.REACT_APP_SERVICEID,
            process.env.REACT_APP_TEMPLATEID,
            form.current,
            process.env.REACT_APP_PUBLICKEY
        )
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        alert("Thank you for reaching out to Matador Labs! we will get back to you shortly.")
    }

    // ui

    const topPop = {
        hidden: { y: -15 },
        visible: {
            y: 0, transition: { duration: 0.6, delay: 0.15, type: 'tween' }
        }
    }

    const bottomPop = {
        hidden: { y: -25 },
        visible: {
            y: 0, transition: { duration: 0.6, delay: 0.15, type: 'tween' }
        }
    }


    const controls = useAnimation();
    const [topPopRef, inView] = useInView();
    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);



    // screen size helper tools
    const [isMobile, setIsMobile] = useState(false);
    const [smScreen, setSmScreen] = useState(false);

    useEffect(() => {
        const updateIsMobile = () => {
            setIsMobile(window.innerWidth <= 1200);
        };

        updateIsMobile();

        const updateExtraSmScreen = () => {
            setSmScreen(window.innerHeight <= 770 && window.innerWidth <= 1200);
        }

        updateExtraSmScreen();

        window.addEventListener('resize', updateIsMobile);
        window.addEventListener('resize', updateExtraSmScreen);

        return () => {
            window.removeEventListener('resize', updateIsMobile);
            window.removeEventListener('resize', updateExtraSmScreen);
        };


    }, []);






    return (
        <div className='landing-container contact-area'>
            <div className="padded-container" ref={connectScrollRef}>
                <AnimatePresence>

                    <div className="side lets-talk">

                        <motion.div
                            ref={topPopRef}
                            animate={controls}
                            initial="hidden"
                            variants={topPop}
                            className="vbig-txt">
                            LET'S <br></br>
                            TALK
                        </motion.div>

                        <motion.div
                            ref={topPopRef}
                            animate={controls}
                            initial="hidden"
                            variants={bottomPop}
                            className="logos-links">
                            <a href='mailto:contact@matadorlabs.co.uk' className="bull-link link">
                                <img src={matadorbullheadlogo} alt="matadorbullheadlogo" className='bullhead' />
                                <p className="link-txt">contact@matadorlabs.co.uk</p>
                            </a>


                            <a href='https://twitter.com/Matador_Labs' className="btwit-link link">
                                <img src={xtwitterlogo} alt="xtwitterlogo" className='xtwit' />
                                <p className="link-txt">@Matador_Labs</p>
                            </a>
                        </motion.div>

                    </div>
                </AnimatePresence>

                <div className="side form-area">
                    <div className="connect-txt">
                        <img src={globelogo} alt="globelogo" />
                        <p className="connect">connect</p>
                    </div>

                    <div className="centered-form">


                        <form ref={form} onSubmit={sendEmail} className="form">

                            <div className="four-items">


                                <div className="one-item left-item">
                                    <div className="title">NAME</div>
                                    <input type="text" className='input-txt' name='name' placeholder='Your Name' required />
                                </div>

                                <div className="one-item right-item">
                                    <div className="title">subject</div>
                                    <select name='subject' className='input-txt' required>
                                        <option value="" defaultValue hidden   >Choose Subject</option>
                                        <option value="Bull Club">Bull Club</option>
                                        <option value="Firebirds">Firebirds</option>
                                        <option value="BabyBulls">BabyBulls</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    {/* <input type="text" className='input-txt' name='subject' placeholder='Choose Subject' required /> */}

                                </div>

                                <div className="one-item left-item">
                                    <div className="title">project</div>
                                    <input type="text" className='input-txt' name='project' placeholder="Project details" />
                                </div>

                                <div className="one-item right-item">
                                    <div className="title">email</div>
                                    <input type="email" className='input-txt' name='email' placeholder='Your email' required />
                                </div>

                            </div>

                            <div className="message-area">
                                <div className="one-item">

                                    <div className="title">message</div>
                                    <textarea className='input-txt message-txt' name='message' placeholder='Type your message here' />

                                    <button type='submit' className="send-button" onSubmit={sendEmail} >
                                        <img src={planelogo} alt="planelogo" />
                                    </button>
                                </div>

                            </div>
                        </form>

                    </div>
                </div>
                {!smScreen && (
                    isMobile ? (
                        <div className="finalfooter">
                            <div className="bottommidlogo">
                                <img src={matadorbullheadlogo} alt="bottommidlogo" />
                            </div>
                            <div className="end-footer-txt">© 2023 Matador Laboratories Limited</div>
                        </div>
                    ) : (
                        <div className="end-footer-txt">© 2023 Matador Laboratories Limited</div>
                    )
                )}
            </div>
        </div >
    )
}
